export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/delcarmon-logo-new.png',
    banner_img_root : '/img/delcarmon-banner.jpg',
    resid : '',
    key_value : 'delcarmon',
    secret_value : 'delcarmon',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2996.013965501471!2d-87.78776418457598!3d41.330309679269476!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e0b56fb70c5b7%3A0x32ff0dff73517f8c!2sDel%20Carmen%20Peotone!5e0!3m2!1sen!2sin!4v1613648896749!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Del Carmen Peotone"
};
